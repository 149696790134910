<template>
  <e-charts
    class="w-100"
    ref="line"
    v-bind="{
      autoresize: true,
      options,
      source,
      responsive: true,
    }"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'
import truncate from 'truncate'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        dataset: {
          source: this.source.acc,
        },
        tooltip: {
          triggerOn: 'mousemove|click',
          formatter: props => {
            return props.data.map((item, index) => {
              const label = props.dimensionNames[index].length < 10 ? props.dimensionNames[index] : truncate(props.dimensionNames[index], 10)
              return index ? `<br><span style=\"display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${this.source.colors[index - 1]};\"></span>"${label}: ${item}` : this.$dayjs(props.name).format('DD.MM.YYYY')
            })
          },
          position: ['50%', '25%'],
        },
        grid: {
          top: screen.width < 480 ? '25%' : '15%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        legend: {
          data: this.source.legend,
          itemGap: 10,
          padding: 0,
          itemHeight: this.source.series.length,
          //itemHeight: this.source.series.length / '14',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            formatter: number => {
              return this.$dayjs(number).format('DD.MM.YYYY')
            },
          },
        },
        yAxis: {
          axisTick: {
            length: false,
          },
          axisLabel: {
            formatter: function(number) {
              return Number.isInteger(number) ? number : ''
            },
          },
        },
        series: this.source.series,
      },
    }
  },
  watch: {
    source(dataset) {
      this.options.dataset.source = dataset.acc
    },
  },
}
</script>

<style lang="scss" scoped>
.echarts-tooltip-line {
    width: 300px;
}
</style>
