<template>
  <b-card-text>
    <b-form-group
      class="d-flex justify-content-center"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        v-model="selected"
        v-bind="{
          source,
          options,
          ariaDescribedby,
          date_from,
          date_to
        }"
      ></b-form-radio-group>
    </b-form-group>
    <app-echart-line v-if="!data_source_loading" v-bind="{
      source,
    }" />
    <div v-else class="text-primary text-center my-2">
      <b-spinner class="align-middle mr-1"></b-spinner>
      <span>Загрузка...</span>
    </div>
  </b-card-text>
</template>

<script>
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    AppEchartLine,
  },
  props: {
    items: {
      type: Object,
      default() {
        return {}
      },
    },
    data_source_loading: {
      type: Boolean,
      default: false,
    },
    date_from: {
      type: [Date, String],
      default() {
        return ''
      },
    },
    date_to: {
      type: [Date, String],
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      selected: 'proceeds',
    }
  },

  computed: {
    options() {
      return [
        { text: 'Выручка', value: 'proceeds' },
        { text: 'Количество заказов', value: 'count' },
      ]
    },

    source() {
      const items = Object.entries(this.items)
      const legend = []
      const series = []
      const colors = []
      const time = Object.values(items).map(item => {
        return new Date(parseInt(item[0], 10)).getTime()
      })

      const timeline = [...time.map(item => item * 1000)]

      // if (time.length === 2) {
      //   timeline = [...time.map(item => item * 1000)]
      // } else {
      //   timeline = [this.date_from.getTime(), ...time.map(item => item * 1000), this.date_to.getTime()].filter(($value, index, self) => {
      //     const formatted = self.map($item => this.$dayjs($item).format('DD.MM.YYYY'))

      //     return formatted[index + 1] !== this.$dayjs($value).format('DD.MM.YYYY')
      //   })
      // }

      // const timeline = [this.date_from.getTime(), ...time.map(item => item * 1000), this.date_to.getTime()].filter(($value, index, self) => {
      //     const formatted = self.map($item => this.$dayjs($item).format('DD.MM.YYYY'))

      //     return formatted[index + 1] !== this.$dayjs($value).format('DD.MM.YYYY')
      //   })


      // TODO to heavy need refactor
      const acc = [['item', ...timeline]]

      items.forEach($item => {
        $item[1].forEach(({ name, proceeds, count, color, interval }) => {
          const delivery = acc.findIndex(item => item[0] === name)
          const date = acc[0].findIndex(item => {
            return item === new Date(interval * 1000).getTime()
          })

          const value = this.selected === 'proceeds' ? (proceeds ? parseFloat(proceeds, 10) : 0) : count ? parseFloat(count, 10) : 0

          legend.push(name)

          if (delivery === -1) {
            const array = new Array(timeline.length).fill(0)

            colors.push(color)
            acc.push([name, ...array])
            series.push({ type: 'line', smooth: true, seriesLayoutBy: 'row', color })
          }

          acc[acc.findIndex(item => item[0] === name)][date] = value
        })
      })

      // // if (timeline.length === 1) {
      // //   acc = [['item', ...timeline]]
      // //   items[0][1].forEach(({ name, proceeds, count, color, interval }) => {
      // //     const value = this.selected === 'proceeds' ? (proceeds ? parseFloat(proceeds, 10) : 0) : count ? parseFloat(count, 10) : 0
      // //     legend.push(name)
      // //     acc.push([name, value])
      // //     series.push({ type: 'line', smooth: true, seriesLayoutBy: 'row', color })
      // //     colors.push(color)
      // //   })
      // // } else {
      // //   acc = items.reduce(
      // //     (acc, item) => {
      // //       item[1].forEach(({ name, proceeds, count, color, interval }) => {
      // //         const delivery = acc.findIndex(item => item[0] === name)
      // //         const date = acc[0].findIndex(item => {
      // //           return item === new Date(interval * 1000).getTime()
      // //         })

      // //         const value = this.selected === 'proceeds' ? (proceeds ? parseFloat(proceeds, 10) : 0) : count ? parseFloat(count, 10) : 0

      // //         legend.push(name)

      // //         if (delivery === -1) {
      // //           const array = new Array(timeline.length).fill(0)

      // //           acc.push([name, ...array])

      // //           colors.push(color)

      // //           series.push({ type: 'line', smooth: true, seriesLayoutBy: 'row', color })
      // //         }

      // //         acc[acc.findIndex(item => item[0] === name)][date] = value
      // //       })
      // //       return acc
      // //     },
      // //     [['item', ...timeline]],
      // //   )
      // }
      return {
        acc,
        legend,
        series,
        colors,
      }
    },
  },
}
</script>
